// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-configurator-js": () => import("./../../../src/pages/configurator.js" /* webpackChunkName: "component---src-pages-configurator-js" */),
  "component---src-pages-demo-1-js": () => import("./../../../src/pages/demo-1.js" /* webpackChunkName: "component---src-pages-demo-1-js" */),
  "component---src-pages-demo-2-js": () => import("./../../../src/pages/demo-2.js" /* webpackChunkName: "component---src-pages-demo-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */)
}

